import brandColors from "./brandColors"

export const lightTheme = {
  body: brandColors.brandWhite,
  navBar: brandColors.brandWhite,
  footer: brandColors.brandWhite,
  navFooterBorder: brandColors.brandFadeBlack,
  text: brandColors.brandBlack,
  textV2: brandColors.brandOffBlack,
  link: brandColors.brandYellow,
  secondaryBackground: brandColors.brandWhite,
  background: brandColors.brandWhite,
  toggleBorder: brandColors.brandBlack,

}
export const darkTheme = {
  body: brandColors.brandDark,
  navBar: brandColors.brandDark,
  footer: brandColors.brandDark,
  navFooterBorder: brandColors.brandFadeWhite,
  text: brandColors.brandWhite,
  textV2: brandColors.brandOffWhite,
  link: brandColors.brandYellow,
  secondaryBackground: brandColors.brandDark,
  background: brandColors.brandYellow,
  toggleBorder: brandColors.brandWhite,
}
