const brandColors = {
  brandYellow: '#feb80a',
  brandOrange: '#e2623d',
  brandMagenta: '#f13556',
  brandTeal: '#70beb0',
  brandBlue: '#3e4890',
  brandGrey: '#888da3',
  brandBlack: '#000000',
  brandOffBlack: '#00000088',
  brandFadeBlack: '#00000022',
  brandWhite: '#ffffff',
  brandOffWhite: '#f5f5f5',
  brandFadeWhite: '#ffffff22',
  brandDark: '#121212'
}

export default brandColors;